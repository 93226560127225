import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { InGet } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiMetricsService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /*
     * Список
     *
     * @param {InGet} obj
     * @return {Promise<Get>}
     */
    get(obj: InGet): Promise<any> {
        return this._request
            .query('arm/v1/metrica', obj)
            .then((res: Response) => res.json);
    }

    getCommerce(obj: InGet): Promise<any> {
        return this._request
            .query('arm/v1/metrica/commerce', obj)
            .then((res: Response) => res.json);
    }

    getCountEvents(obj: any): Promise<any> {
        return this._request
            .query('arm/v1/metrica/orders', obj)
            .then((res: Response) => res.json);
    }

    getTrackers(obj: any): Promise<any> {
        return this._request
            .query('arm/v1/metrica/trackers', obj)
            .then((res: Response) => res.json);
    }

    getSessions(obj: any): Promise<any> {
        return this._request
            .query('arm/v1/metrica/sessions', obj)
            .then((res: Response) => res.json);
    }

    getTopFavorites(obj: any): Promise<any> {
        return this._request
            .query('arm/v1/metrica/favorites', obj)
            .then((res: Response) => res.json);
    }

}

