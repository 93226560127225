import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { Get, InGet, InPut } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiCardsService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /**
     * Список пользователей
     *
     * @param {InGet} obj
     * @returns {Promise<Get>}
     */
    get(obj?: InGet): Promise<any> {
        return this._request
            .query('arm/v1/cards/banned', obj)
            .then((res: Response) => res.json);
    }

    activate(_obj?: InPut): Promise<any> {
        return this._request
            .update('arm/v1/cards/banned/activate', _obj)
            .then((res: Response) => res.json);
    }
}
