import { Injectable } from '@angular/core';
import { ApiRequest, Response } from '../../../request';
import { InPost, InPut, InGetSeasonal, GetSeasonal } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from '../../../utils';

@Injectable()
export class ApiSeasonalService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /*
     * Создание
     *
     * @param {InPost} obj
     * @return {Promise<any>}
     */
    post(obj: InPost, files: File[] = []): Promise<any> {
        return this._request.create('arm/v1/seasonal', obj, files)
            .then((res: Response) => res.json);
    }

    /*
     * Список
     *
     * @param {InGet} obj
     * @return {Promise<Get>}
     */
    get(obj: InGetSeasonal): Promise<GetSeasonal> {
        return this._request
            .query('arm/v1/seasonal', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }

    /**
     * Обновление
     * @param obj
     * @param files
     * @returns {Promise<{result: boolean}|TResult2|TResult1>}
     */
    put(obj: InPut, files: File[] = []): Promise<any> {
        return this._request
            .update('arm/v1/seasonal', obj, files)
            .then((res: Response) => res.json);
    }

    getItems(obj?: any): Promise<any> {
        return this._request
            .query('arm/v1/seasonal/items', obj)
            .then((res: Response) => res.json);
    }
}

