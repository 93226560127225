import { Injectable } from '@angular/core';
import { Cookie } from '../cookie';
import { ApiService } from '../api';
import { BehaviorSubject } from 'rxjs';
import { Router } from "@angular/router";

@Injectable()
export class Auth {
    private nameAuthToken = 'auth_token';
    private nameUser = 'login';

    public resultCheck: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public permission: BehaviorSubject<string[]> = new BehaviorSubject(null);

    constructor(private _api: ApiService, private _cookie: Cookie, private _router: Router) { }

    /**
     * Получение токена
     *
     * @param {string} login
     * @param {string} password
     *
     * @returns {Promise<boolean>}
     */
    login(login: string, password: string) {

        const obj = {
            login: login,
            password: password
        };

        return this._api
            .authLogin.put(obj)
            .then((res: any) => {
                this.setToken(res.json[this.nameAuthToken]);
                this.setUser(login);
                this.check();
                return true;
            });
    }

    /**
     * Выход
     * 
     * @returns {Promise<boolean>}
     **/
    logout() {
        return this._api
            .authLogout.put()
            .then((res: any) => {
                return this.check().then(() => {
                    return true;
                });
            }).catch(() => {
                return false;
            });
    }

    /**
     * Запись auth token
     *
     * @param {string} token
     */
    setToken(token: string): void {
        if (token.length === 64) {
            this._cookie.setItem(this.nameAuthToken, token);
        }
    }

    /**
     * Возращает токен
     *
     * @returns {string}
     */
    getToken(): string {
        return <string>this._cookie.getItem(this.nameAuthToken);
    }


    /**
     *   Проверка токена
     * @returns {Promise<boolean>}
     */
    check(): Promise<boolean> {
        if (this.getToken()) {
            return this._api
                .authCheck
                .post()
                .then((res: any) => {
                    const result = <boolean>res.result;
                    this.resultCheck.next(result);
                    this.permission.next(res.permissions);
                    if (result === false) {
                        this._cookie.removeItem(this.nameAuthToken);
                    }

                    return result;
                }).catch((err: any) => {
                    this._cookie.removeItem(this.nameAuthToken);
                    this.resultCheck.next(false);
                    return false;
                });
        }

        return new Promise((resolve) => {
            resolve(false);
        });
    }

    /**
     *  Запись логина
     * @param login
     */

    setUser(login: string): void {
        if (login.length > 0) {
            this._cookie.setItem(this.nameUser, login);
        }
    }

    /**
     * Получение логина
     * @returns {string}
     */
    getUser(): string {
        return <string>this._cookie.getItem(this.nameUser);
    }

    checkUrlPath(url: string) {
        const arrUrl = url.replace(/\?.*/, '').split('/').slice(1);
        const permission = this.permission.getValue();
        if (permission) {
            if (arrUrl[0] !== 'dashboard') {
                const f = permission.indexOf(arrUrl[0]);
                if (f !== -1) {
                    return true;
                } else {
                    this._router.navigate(['/']);
                    return false;
                }
            } else {
                return true;
            }
        }
    }
}
