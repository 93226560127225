import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import {Get, InGet, InPut, InPutLoad, InPutLoadOrders} from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiCertsLegalService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }


    get(obj?: InGet): Promise<Get> {
        return this._request
            .query('arm/v1/certs-legal', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }

    post(obj?: InPut): Promise<Get> {
        return this._request
            .create('arm/v1/certs-legal', obj)
            .then((res: Response) => res.json);
    }

    load(obj?: InPutLoad): Promise<any> {
        return this._request
            .update('arm/v1/certs-legal/load', obj)
            .then((res: Response) => res.json);
    }

    getTypes(obj?: InGet): Promise<any> {
        return this._request
            .query('arm/v1/certs-legal/types', obj)
            .then((res: Response) => res.json);
    }

    getOrders(obj?: InGet): Promise<any> {
        return this._request
            .query('arm/v1/certs-legal/orders', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }

    loadOrder(obj?: InPutLoadOrders): Promise<any> {
        return this._request
            .update('arm/v1/certs-legal/orders/load', obj)
            .then((res: Response) => res.json);
    }
}
