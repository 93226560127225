import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { InGetCode } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiAdministrationService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    getCode(obj: InGetCode): Promise<any> {
        return this._request.update('arm/v1/administration/devices', obj)
            .then((res: Response) => {
                return res.json;
            })
    }

}

