import { Injectable } from '@angular/core';
import { ApiRequest, Response } from '../../../request';
import { Get, InPut } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from '../../../utils';

@Injectable()
export class ApiAccentService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    get(): Promise<Get> {
        return this._request
            .query('arm/v1/content-site/accent')
            .then((res: Response) => res.json);
    }

    save(obj?: InPut, files: File[] = []): Promise<Get> {
        return this._request
            .update('arm/v1/content-site/accent', obj, files)
            .then((res: Response) => res.json);
    }
}
