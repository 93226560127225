import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';
import { GetOrdersStatus } from '@core/service/api/methods/orders-status/interface';

@Injectable()
export class ApiOrdersStatusService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }


    /*
     * Получение списка статусов
     *
     * @return {Promise<GetOrdersStatus>}
     */
    get(): Promise<GetOrdersStatus> {
        return this._request
            .query('ma/arm/v1/orders/statuses')
            .then((res: Response) => res.json);
    }

}

