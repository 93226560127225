export const environment = {
    production: false,
    api: {
        url: 'pz-api.vigroup.ru',
        secret: 'v9qCEkUX5ln06QipE5rgUajoJKO86cvZUhA8o6nJVVBObvoH9J8KAx3ZIamJbMXn',
        port: '',
        protocol: 'https',
        ncp: true,
        header: {
            'Content-Type': 'application/json',
            'platform': 3
        }
    },
    debug: false,
    site: {
        date: {
            full: 'DD.MM.YYYY HH:mm',
            time: 'HH:mm',
            date: 'DD.MM.YYYY'
        },
        def: {
            elementCount: 15,
            startPage: 1,
            autoCompleteCount: 10,
            perPageList: [5, 15, 30],
            showPageCount: 7
        },
        hostLinkIos: 'https://pzdev.page.link/',
        hostLinkAndroid: 'https://play.google.com/',
        hostLinkSite: 'https://pz-shop.vigroup.ru/',
        hostLinkProdSite: 'https://planetazdorovo.ru/',
        updateTime: 5, // Интервал обновления данных, в секундах
        checkAuthTime: 5, // Интервал проверки авторизации, в секундах
        logoutTime: 30, // Время бездействия после которого нужна будет авторизация, в минутах
    },

};
