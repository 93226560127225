import { Injectable } from "@angular/core";
import { ApiRequest, Response } from "./../../request";
import { ActivatedRoute } from "@angular/router";
import { Config } from "@core/service/config";
import { ApiUtilsService } from "./../../utils";
import { Get, InGet, InPut } from "@core/service/api/methods/selections/interface";

@Injectable()
export class ApiSelectionsService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }


    /*
     * Получение списка выборок
     *
     * @return {Promise<Get>}
     */
    get(obj?: InGet): Promise<Get> {
        return this._request
            .query('arm/v1/selections', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }

    /*
     * Получение списка акций
     *
     * @return {Promise<Get>}
     */
    getCards(obj?: any): Promise<any> {
        return this._request
            .query('arm/v1/selections/cards', obj)
            .then((res: Response) => res.json);
    }


    /*
     * обновление выборки
     *
     * @return {Promise<Interface.ListSpecials>}
     */
    put(obj?: InPut, files: File[] = []): Promise<any> {
        return this._request
            .update('arm/v1/selections', obj, files)
            .then((res: Response) => res.json);
    }

    /*
     * создание новой выборок
     *
     * @return {Promise<Interface.ListSpecials>}
     */
    post(obj?: InPut, files: File[] = []): Promise<any> {
        return this._request
            .create('arm/v1/selections', obj, files)
            .then((res: Response) => res.json);
    }

    /**
     * Запрос расчета выборки
     *
     * @param {number} id
     * @param files
     * @returns {*}
     */

    calculate(id, files: File[] = []): Promise<any> {
        return this._request
            .update('arm/v1/selections/perform', id, files)
            .then((res: Response) => res.json);
    }

    makeXls(obj, files: File[] = []): Promise<any> {
        return this._request
            .update('arm/v1/selections/xlsx', obj, files)
            .then((res: Response) => res.json);
    }


}

