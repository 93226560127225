import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { InPost, Get, InGet, InRepeat, InRemove, InCalc, InGetCalc } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiNotificationsService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /*
     * Создание и отправка
     *
     * @param {InPost} obj
     * @return {Promise<any>}
     */
    post(obj: InPost, files: File[] = []): Promise<any> {
        return this._request.create('arm/v1/notifications', obj, files)
            .then((res: Response) => {
                return res.json;
            })
    }

    /*
     * Список уведомлений
     *
     * @param {InGet} obj
     * @return {Promise<Get>}
     */
    get(obj: InGet): Promise<Get> {
        return this._request
            .query('arm/v1/notifications', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }

    /**
     * Повтор рассылки
     * @param {InRepeat} obj
     * @returns {Promise<{result: boolean}|TResult2|TResult1>}
     */
    repeat(obj: InRepeat): Promise<InGet> {
        return this._request.create('arm/v1/notifications/repeat', obj)
            .then((res: Response) => {
                return res.json;
            })
    }

    calc(obj: InCalc): Promise<InGetCalc> {
        return this._request.query('arm/v1/notifications/calc', obj)
            .then((res: Response) => {
                return res.json;
            })
    }

    /**
     * Удаление рассылки
     * @param obj
     * @returns {Promise<{result: boolean}|TResult2|TResult1>}
     */
    remove(obj: InRemove): Promise<any> {
        return this._request.update('arm/v1/notifications', obj)
            .then((res: Response) => {
                return res.json;
            })
    }
}

