import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';
import { Get } from './interface';

@Injectable()
export class ApiNotificationsAttachmentTypesService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /*
     * Получение списка экранов
     *
     * @return {Promise<Interface.ListNotificationsAttachmentTypes>}
     */
    get(): Promise<Get> {
        return this._request
            .query('arm/v1/notifications/attachment-types')
            .then((res: Response) => res.json);
    }

}

