import { Injectable } from '@angular/core';
import { ApiRequest } from './request';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import * as _ from 'lodash';

@Injectable()
export class ApiUtilsService {

    constructor(protected _request: ApiRequest, protected _activatedRoute: ActivatedRoute, protected _config: Config) {
    }


    /**
     * Количество по умолчанию (все 500)
     *
     * @param obj
     * @returns {any}
     */
    protected setCount(obj?: any) {
        let tmp = obj;
        if (tmp === undefined) {
            tmp = {};
        }
        if (!tmp.count) {
            tmp['count'] = 20;
        }

        return tmp;
    }


    /**
     * Собирает параметры с url
     *
     * Количество по умолчанию (все 500)
     *
     * @param obj
     * @returns {any}
     */
    protected setParamsUrl(obj?: any) {
        const params: any = {};
        const config = this._config.get('site')['def'];
        let tmp: any = {};
        /**
         * Убираем пустые значение
         */
        _.forEach(this._activatedRoute.snapshot.queryParams, (v, k) => {
            if (v) {
                if (k !== 'start_date' && k !== 'end_date') params[k] = v;
            }
        });
        _.forEach(obj, (v, k) => {
            if (v) {
                tmp[k] = v;
            }
        });

        /**
         * Вставляем значение по умолчанию если есть пустые значение
         */
        if (tmp === undefined) {
            tmp = {};
        }

        if (!params.count) {
            tmp['count'] = config.elementCount;
        } else {
            if (!tmp.count) {
                tmp.count = params.count;
            }
        }
        if (!params.page) {
            tmp['page'] = config.startPage;
        } else {
            if (!tmp.page) {
                tmp.page = params.page;
            }
        }

        return Object.assign(tmp, params);
    }
}

