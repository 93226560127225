import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { Get, InGet } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiSearchAnalyticsService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /**
     * Список топ запросов
     *
     * @param {InGet} obj
     * @returns {Promise<Get>}
     */
    getTop(obj?: InGet): Promise<any> {
        return this._request
            .query('arm/v1/search_analytics/top', obj)
            .then((res: Response) => res.json);
    }

    /**
     * Список запросов без результатов
     *
     * @param {InGet} obj
     * @returns {Promise<Get>}
     */
    getWithoutRes(obj?: InGet): Promise<any> {
        return this._request
            .query('arm/v1/search_analytics/without_results', obj)
            .then((res: Response) => res.json);
    }

    getVisual(obj?: InGet): Promise<any> {
        return this._request
            .query('arm/v1/search_analytics/visual', obj)
            .then((res: Response) => res.json);
    }
}
