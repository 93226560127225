import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class TemplateComponent implements OnInit {

    public story: any;
    public btn_add: boolean = false;

    constructor() {
    }

    ngOnInit() {
        this.story = window['story'];
        if (this.story.button_text) {
            this.btn_add = true;
        }
    }


    appliedHorizontAlign() {
        switch (this.story.horizont_align) {
            case 'right':
                return 'right';
                break;
            case 'center':
                return 'center';
                break;
            case 'left':
                return 'left';
                break;
        }
    }

    appliedVerticalAlign() {
        switch (this.story.vertical_align) {
            case 'top':
                return 'type1';
                break;
            case 'bottom':
                return 'type3';
                break;
        }
    }
}
