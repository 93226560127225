import { Injectable } from "@angular/core";
import { ApiRequest, Response } from "./../../request";
import { ActivatedRoute } from "@angular/router";
import { Config } from "@core/service/config";
import { ApiUtilsService } from "./../../utils";
import { Get, InGet, InPut } from "@core/service/api/methods/reclames/interface";

@Injectable()
export class ApiReclamesService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }


    /*
     * Получение списка подборок
     *
     * @return {Promise<Get>}
     */
    get(obj?: InGet): Promise<Get> {
        return this._request
            .query('arm/v1/reclames', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }

    /*
     * Получение списка товаров в подборке
     *
     * @return {Promise<Get>}
     */
    getItems(obj?: any): Promise<any> {
        return this._request
            .query('arm/v1/reclames/items', obj)
            .then((res: Response) => res.json);
    }
    /*
     * создание новой подборки
     *
     * @return {Promise<Interface.ListSpecials>}
     */
    post(obj?: InPut, files: File[] = []): Promise<any> {
        return this._request
            .create('arm/v1/reclames', obj, files)
            .then((res: Response) => res.json);
    }



}

