import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiSiteImageService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /*
     * Создание
     *
     * @param {InPost} obj
     * @return {Promise<any>}
     */
    post(files: File[] = []): Promise<any> {
        return this._request.create('arm/v1/content-site/image', {}, files)
            .then((res: Response) => res.json)
    }
}

