import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { Get, InGet } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiAppealsService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }


    get(obj?: InGet): Promise<Get> {
        return this._request
            .query('arm/v1/appeals', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }
}
