
    <div class="container_template"
         [ngStyle]="{'background-image': 'url(' + story.background_url + ')'}"
         [ngClass]="story.vertical_align">
        <div class="button_wrap" *ngIf="btn_add">
            <div class="button">{{(story.button_text)}}</div>
        </div>
        <div class="type" [ngClass]="[appliedHorizontAlign(), appliedVerticalAlign()]">
            <div class="title">{{story.title}}</div>
            <div class="descr">{{story.body}}</div>
        </div>
    </div>

