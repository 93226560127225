import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiYandexService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /*
     * Загрузка файла
     *
     * @return {Promise<Get>}
     */
    download(obj?: any): Promise<any> {
        return this._request
            .update('arm/v1/yandex/feed', (obj))
            .then((res: Response) => res.json);
    }

}

