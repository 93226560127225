import { Injectable } from "@angular/core";
import { ApiRequest, Response } from "./../../request";
import { ActivatedRoute } from "@angular/router";
import { Config } from "@core/service/config";
import { ApiUtilsService } from "./../../utils";
import { Get, InGet, InPut } from "@core/service/api/methods/feedback/interface";

@Injectable()
export class ApiFeedbackService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }


    /*
     * Получение списка слайдов
     *
     * @return {Promise<Get>}
     */
    get(obj?: InGet): Promise<Get> {
        return this._request
            .query('arm/v1/items/feedback', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }

    /*
     * Обновление слайда
     *
     * @return {Promise<Interface.ListSpecials>}
     */
    put(obj?: InPut, files: File[] = []): Promise<any> {
        return this._request
            .update('arm/v1/items/feedback', obj, files)
            .then((res: Response) => res.json);
    }
}

