import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { Get, GetDeliveryAndPickup, InGet, InPut, InPutDeliveryAndPickup } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiParamsService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /**
     * Список городов
     *
     * @param {InGet} obj
     * @returns {Promise<Get>}
     */
    get(obj?: InGet): Promise<Get> {
        return this._request
            .query('arm/v1/parameters', obj)
            .then((res: Response) => res.json);
    }

    post(obj?: InPut): Promise<Get> {
        return this._request
            .create('arm/v1/parameters', (obj))
            .then((res: Response) => res.json);
    }

    getDelivery(obj?: any): Promise<GetDeliveryAndPickup> {
        return this._request
            .query('arm/v1/parameters/delivery', obj)
            .then((res: Response) => res.json);
    }

    getPickup(obj?: any): Promise<GetDeliveryAndPickup> {
        return this._request
            .query('arm/v1/parameters/pickup', obj)
            .then((res: Response) => res.json);
    }

    postDelivery(obj?: InPutDeliveryAndPickup): Promise<GetDeliveryAndPickup> {
        return this._request
            .create('arm/v1/parameters/delivery', (obj))
            .then((res: Response) => res.json);
    }

    postPickup(obj?: InPutDeliveryAndPickup): Promise<GetDeliveryAndPickup> {
        return this._request
            .create('arm/v1/parameters/pickup', (obj))
            .then((res: Response) => res.json);
    }

    getCerts(obj?: any): Promise<GetDeliveryAndPickup> {
        return this._request
            .query('arm/v1/parameters/certs', obj)
            .then((res: Response) => res.json);
    }

    postCerts(obj?: InPutDeliveryAndPickup): Promise<GetDeliveryAndPickup> {
        return this._request
            .create('arm/v1/parameters/certs', (obj))
            .then((res: Response) => res.json);
    }
}
