import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';
import { Get } from '@core/service/api/methods/specials-statuses/interface';

@Injectable()
export class ApiSpecialsStatusesService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /*
     * Список статусов акций
     *
     * @return {Promise<Get>}
     */
    get(): Promise<Get> {
        return this._request
            .query('arm/v1/specials/statuses')
            .then((res: Response) => res.json);
    }
}
