import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { Get, InGet, InPut, InPutCancel, InPutLoad } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiCertsService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }


    get(obj?: InGet): Promise<Get> {
        return this._request
            .query('arm/v1/certificates', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }

    getOrders(obj?: InGet): Promise<any> {
        return this._request
            .query('arm/v1/certificates/orders', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }

    post(obj?: InPut, files: File[] = []): Promise<Get> {
        return this._request
            .create('arm/v1/certificates', obj, files)
            .then((res: Response) => res.json);
    }

    put(obj?: InPut, files: File[] = []): Promise<Get> {
        return this._request
            .update('arm/v1/certificates', obj, files)
            .then((res: Response) => res.json);
    }

    getValues(obj?: InGet): Promise<any> {
        return this._request
            .query('arm/v1/certificates/values', obj)
            .then((res: Response) => res.json);
    }

    getExpiration(obj?: InGet): Promise<any> {
        return this._request
            .query('arm/v1/certificates/expiration', obj)
            .then((res: Response) => res.json);
    }

    cancel(obj?: InPutCancel): Promise<any> {
        return this._request
            .update('arm/v1/certificates/cancel', obj)
            .then((res: Response) => res.json);
    }

    load(obj?: InPutLoad): Promise<any> {
        return this._request
            .update('arm/v1/certificates/load', obj)
            .then((res: Response) => res.json);
    }
}
