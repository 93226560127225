import { NgModule } from '@angular/core';
import { PagesComponent } from './pages.component';
import { Routes, RouterModule } from '@angular/router';
import { Guard } from '@core/service/auth/guard';
import { MetaGuard } from '@ngx-meta/core';

const routes: Routes = [
    {
        path: '',
        component: PagesComponent,
        canActivate: [Guard],
        canActivateChild: [Guard, MetaGuard],
        children: [
            {
                path: 'notifications',
                loadChildren: './notifications/notifications.module#NotificationsModule',
            },
            {
                path: 'instruction',
                loadChildren: './instruction/instruction.module#InstructionModule',
            },
            {
                path: 'feedback',
                loadChildren: './feedback/feedback.module#FeedbackModule',
            },
            {
                path: 'stats',
                loadChildren: './stats/stats.module#StatsModule',
            },
            {
                path: 'commerce',
                loadChildren: './commerce/commerce.module#CommerceModule',
            }, {
                path: 'involvement',
                loadChildren: './involvement/involvement.module#InvolvementModule',
            }, {
                path: 'cart',
                loadChildren: './cart/cart.module#CartModule',
            }, {
                path: 'search_analytics',
                loadChildren: './search_analytics/search-analytics.module#SearchAnalyticsModule',
            }, {
                path: 'users',
                loadChildren: './users/users.module#UsersModule',
            }, {
                path: 'reclame',
                loadChildren: './reclame/reclame.module#ReclameModule',
            }, {
                path: 'promocodes',
                loadChildren: './promocodes/promocodes.module#PromocodesModule',
            }, {
                path: 'questions',
                loadChildren: './questions/questions.module#QuestionsModule',
            }, {
                path: 'exch',
                loadChildren: './exch/exch.module#ExchModule',
            }, {
                path: 'functions',
                loadChildren: './functions/functions.module#FunctionsModule',
            }, {
                path: 'permission',
                loadChildren: './permission/permission.module#PermissionModule',
            }, {
                path: 'dashboard',
                loadChildren: './dashboard/dashboard.module#DashboardModule',
            }, {
                path: 'certs',
                loadChildren: './certs/certs.module#CertsModule',
            }, {
                path: 'certs-legal',
                loadChildren: './certs-legal/certs-legal.module#CertsLegalModule',
            }, {
                path: 'appeals',
                loadChildren: './appeals/appeals.module#AppealsModule',
            }, {
                path: 'stories',
                loadChildren: './stories/stories.module#StoriesModule',
            }, {
                path: 'content-site',
                loadChildren: './content-site/content-site.module#ContentSiteModule',
            }, {
                path: 'recovery-cert',
                loadChildren: './recovery-cert/recovery-cert.module#RecoveryCertModule',
            }, {
                path: 'orders',
                loadChildren: './orders/orders.module#OrdersModule',
            }, {
                path: 'socials',
                loadChildren: './socials/socials.module#SocialsModule',
            }, {
                path: 'programs',
                loadChildren: './programs/programs.module#ProgramsModule',
            }, {
                path: 'offers',
                loadChildren: './offers/offers.module#OffersModule',
            }, {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            }

        ],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PagesRoutingModule {
}
