import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { InPut, InPutLoad } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiRecoveryCertService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    put(obj?: InPut): Promise<any> {
        return this._request
            .update('arm/v1/recovery-cert', obj)
            .then((res: Response) => res.json);
    }

    download(obj?: InPutLoad): Promise<any> {
        return this._request
            .update('arm/v1/recovery-cert/load', obj)
            .then((res: Response) => res.json);
    }
}
