<!-- BEGIN: Left Aside -->
<button class="m-aside-left-close  m-aside-left-close--skin-dark" id="m_aside_left_close_btn" appunwraptag="">
    <i class="la la-close"></i>
</button>
<div id="m_aside_left" class="m-grid__item	m-aside-left  m-aside-left--skin-dark">
    <!-- BEGIN: Aside Menu -->
    <div id="m_ver_menu" class="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark" data-menu-vertical="true"
         data-menu-scrollable="false" data-menu-dropdown-timeout="500">
        <ul class="m-menu__nav  m-menu__nav--dropdown-submenu-arrow">
            <ng-container *ngFor="let menu of list">
                <li class="m-menu__item  m-menu__item--submenu"
                    routerLinkActive="m-menu__item--open"
                    routerLinkActiveOptions="{ exact: true }"
                    aria-haspopup="true"
                    data-menu-submenu-toggle="hover">
                    <a class="m-menu__link m-menu__toggle">
                        <i class="m-menu__link-icon {{menu.icon}}"></i>
                        <span class="m-menu__link-text">
						{{menu.title}}
					</span>
                        <i class="m-menu__ver-arrow la la-angle-right"></i>
                    </a>
                    <ng-container *ngIf="menu.children">
                        <div class="m-menu__submenu" >
                            <span class="m-menu__arrow"></span>
                            <ul class="m-menu__subnav">
                                <ng-container *ngFor="let children1 of menu.children">
                                    <ng-container *ngIf="children1.title">
                                        <ng-container *ngIf="children1.children">
                                            <li class="m-menu__item"
                                                routerLinkActive="m-menu__item--open"
                                                routerLinkActiveOptions="{ exact: true }"
                                                aria-haspopup="true"
                                                data-menu-submenu-toggle="hover">
                                                <a class="m-menu__link m-menu__toggle">
                                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot">
                                                        <span></span>
                                                    </i>
                                                    <span class="m-menu__link-text">
                                            {{children1.title}}
                                            </span>
                                                    <i class="m-menu__ver-arrow la la-angle-right"></i>
                                                </a>

                                                <div class="m-menu__submenu" *ngIf="children1.children">
                                                    <ng-container *ngFor="let children2 of children1.children">
                                                        <span class="m-menu__arrow"></span>
                                                        <ul class="m-menu__subnav">
                                                            <li class="m-menu__item" routerLinkActive="m-menu__item--active"
                                                                routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
                                                                <a routerLink="{{children2.link}}" class="m-menu__link">
                                                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot">
                                                                        <span></span>
                                                                    </i>
                                                                    <span class="m-menu__link-text">
                                             {{children2.title}}
                                            </span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </ng-container>
                                                </div>
                                            </li>
                                        </ng-container>
                                        <ng-container *ngIf="!children1.children">
                                            <li class="m-menu__item" routerLinkActive="m-menu__item--active" routerLinkActiveOptions="{ exact: true }"
                                                aria-haspopup="true">
                                                <a routerLink="{{children1.link}}" class="m-menu__link">
                                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot">
                                                        <span></span>
                                                    </i>
                                                    <span class="m-menu__link-text">
                                                        {{children1.title}}
                                                    </span>
                                                </a>
                                            </li>
                                        </ng-container>

                                    </ng-container>
                                    <ng-container *ngIf="!children1.title">
                                        <li style="display: none">
                                            <a routerLink="children1.link" class="m-menu__link"></a>
                                        </li>
                                    </ng-container>
                                </ng-container>
                            </ul>
                        </div>
                    </ng-container>
                </li>
            </ng-container>

        </ul>
    </div>
    <!-- END: Aside Menu -->
</div>
<!-- END: Left Aside -->