import { Injectable } from "@angular/core";
import { ApiRequest, Response } from "./../../request";
import { Get, InGet } from "./interface";
import { ActivatedRoute } from "@angular/router";
import { Config } from "@core/service/config";
import { ApiUtilsService } from "./../../utils";

@Injectable()
export class ApiBasketService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /**
     * Список городов
     *
     * @param {InGet} obj
     * @returns {Promise<Get>}
     */
    getStats(obj?: InGet): Promise<any> {
        return this._request
            .query('arm/v1/cart/stats', obj)
            .then((res: Response) => res.json);
    }
}
