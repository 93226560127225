<div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin" id="m_login">
    <div class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside">
        <div class="m-stack m-stack--hor m-stack--desktop">
            <div class="m-stack__item m-stack__item--fluid">
                <div class="m-login__wrapper">
                    <div class="m-login__logo">
                        <a href="#">
                            <img src="./assets/app/media/img//logos/logo-big-vitamin.png">
                        </a>
                    </div>
                    <div class="m-login__signin">
                        <form [formGroup]="form" (ngSubmit)=" OnClickSignIn()" #f="ngForm" class="m-login__form m-form" action="">
                            <div *ngIf="messageFromApi"
                                 class="m-alert m-alert--outline alert alert-danger alert-dismissible"
                                 role="alert">
                                <span>{{messageFromApi}}</span>
                            </div>
                            <div *ngIf="form.get('login').invalid && submit"
                                 class="m-alert m-alert--outline alert alert-danger alert-dismissible"
                                 role="alert">
                                <span>Не заполнено поле: Имя пользователя</span>
                            </div>
                            <div *ngIf="form.get('password').invalid && submit"
                                 class="m-alert m-alert--outline alert alert-danger alert-dismissible"
                                 role="alert">
                                <span>Не заполнено поле: Пароль</span>
                            </div>
                            <div class="form-group m-form__group">
                                <input  formControlName="login"
                                        class="form-control m-input"
                                        type="text"
                                        placeholder="Имя пользователя"
                                        name="login"
                                        autocomplete="off"
                                        style="padding: 10px;">
                            </div>
                            <div class="form-group m-form__group">
                                <input formControlName="password"
                                       class="form-control m-input m-login__form-input--last"
                                       type="password"
                                       placeholder="Пароль"
                                       style="padding: 10px;"
                                       name="password">
                            </div>
                            <div class="m-login__form-action">
                                <button [disabled]="loading" [ngClass]="{'m-loader m-loader--right m-loader--light': loading}" id="m_login_signin_submit" class="btn btn-brand m-btn--air">
                                    Войти
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1	m-login__content" style="background-image: url(./assets/app/media/img//bg/bg-4.jpg)">
        <div class="m-grid__item m-grid__item--middle" style="text-align: center;">
            <h3 class="m-login__welcome">
                Автоматизированное
                <br>
                рабочее место
            </h3>
        </div>
    </div>
</div>
